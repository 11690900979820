<template>
  <div :class="$style.warningModal">
    <p>请输入摄像头名称，长度不得超过12个字符</p>
    <a-input
      :class="$style.textarea"
      v-model="cameraName"
      placeholder="请输入摄像头的名称"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { updateCameraName } from '@/services/monitor/client';
@Component
export default class EditCamera extends Vue {
  @Prop({ type: Object }) data;
  @Prop({ type: Function }) change;

  cameraName = '';
  @Watch('data', { immediate: true })
  onDataChange(value) {
    this.cameraName = value.cameraName;
  }

  sendLoading = false;
  async updateCameraNameApi() {
    this.sendLoading = true;
    try {
      await updateCameraName({
        cameraId: this.data.pkCameraId,
        cameraName: this.cameraName,
      });
      this.change(this.cameraName);
      this.close();
    } catch (error) {
      this.$message.error(error.message);
    } finally {
      this.sendLoading = false;
    }
  }
  @Emit('close')
  close() {}
}
</script>

<style lang="less" module>
@commonColor: rgba(13, 200, 254, 1);
.warningModal {
  padding: 0 0.2rem;
  color: #fff;
  .textarea {
    border-color: @commonColor;
    margin-top: 0.1rem;
    color: #fff;
    font-size: 0.14rem;
    :global(.ant-input) {
      font-size: 0.14rem;
    }
  }
  p {
    margin: 0.2rem 0;
    font-size: 0.14rem;
  }
}
</style>
