import { promiseLock } from '@triascloud/utils';
import request from '../request';
const serviceName = '/monitor/client';

/**
 * @name 更改摄像名称
 * @param { Object } data
 */
export function updateCameraName(data) {
  return request(`${serviceName}/camera/updateCameraName`, {
    method: 'POST',
    body: data,
    closeMessage: true,
  });
}
/**
 * @name 投递楼栋预警信息
 * @param { Object } data
 */
export function sendWarningMessage(data) {
  return request(`${serviceName}/camera/sendWarningMessage`, {
    method: 'POST',
    body: data,
    closeMessage: true,
  });
}
/**
 * @name 人工截图
 * @param { Object } data
 */
export function monitorScreenshot(data) {
  return request(`${serviceName}/camera/monitorScreenshot`, {
    method: 'POST',
    body: data,
    closeMessage: true,
  });
}
/**
 * @name 删除人工截图
 * @param { Object } data
 */
export function delteMonitorScreenshot(id) {
  return request(`${serviceName}/camera/monitorScreenshot/${id}`, {
    method: 'DELETE',
    closeMessage: true,
  });
}

// GET 获取7日监控截图
export function getScreenshot(data) {
  return request(`${serviceName}/camera/screenshot`, {
    method: 'POST',
    body: data,
    closeMessage: true,
    auth: false,
  });
}

/**
 * @name 查看设摄像头实时画面列表(项目概览页面)
 */
export function getCameraList(data) {
  return request(`${serviceName}/camera/listView`, {
    method: 'POST',
    body: data,
    closeMessage: true,
    auth: false,
  });
}

/**
 * @name 查看设摄像头实时画面列表(项目概览页面)加promise锁
 */
export const getCameraListByLock = promiseLock(
  async function(data) {
    return getCameraList(data);
  },
  {
    keyGenerator: data => data,
    global: true,
    forever: true,
  },
);
