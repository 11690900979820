<template>
  <div :class="$style.warningModal">
    <p><span :class="$style.point">1</span>预警内容截图</p>
    <img :src="data.screenshotUrl" />
    <p><span :class="$style.point">2</span>预警方式设置</p>
    <div>
      <a-radio-group
        :options="warningNoticeTypes"
        v-model="warningNoticeType"
      />
    </div>
    <p><span :class="$style.point">3</span>预警内容输入</p>
    <div>
      <a-radio-group :options="issueTypes" v-model="issueType" />
    </div>
    <a-textarea
      :class="$style.textarea"
      :disabled="disabled"
      v-model="problemDescription"
      placeholder="请输入问题描述"
      :auto-size="{ minRows: 5 }"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { sendWarningMessage } from '@/services/monitor/client';
import { createModuleUrl } from '@/utils';

const issueTypes = [
  { value: 'SAFETY', label: '安全问题' },
  { value: 'STAFF', label: '人员问题' },
  { value: 'OTHER', label: '其他问题' },
];
const warningNoticeTypes = [
  {
    value: 'SMS_AND_SYSTEM',
    label: '短信+系统通知',
  },
  {
    value: 'SYSTEM',
    label: '系统通知',
  },
];
@Component
export default class WarningMesForm extends Vue {
  @Prop({ type: Object, required: true }) data;
  @Prop({ type: Object, required: true }) third;

  issueTypes = issueTypes;
  warningNoticeTypes = warningNoticeTypes;
  issueType = 'SAFETY';

  disabled = true;
  @Watch('warningNoticeType', { immediate: true })
  onWarningNoticeTypesChange(value) {
    if (value === 'SMS_AND_SYSTEM') {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }
  @Watch('issueType', { immediate: true })
  onIssueTypeChange(value) {
    const { projectName, buildingName } = this.third;
    const item = issueTypes.find(v => v.value === value);
    this.problemDescription = `【三叠云】您的${projectName}的${buildingName}楼栋疑似有${item.label}隐患，请及时注意检查`;
  }

  warningNoticeType = 'SMS_AND_SYSTEM';
  problemDescription = '';
  sendLoading = false;
  async sendMes() {
    if (this.issueType === 'OTHER' && !this.problemDescription.length) {
      this.$message.warning('请输入问题描述');
      return;
    }
    this.sendLoading = true;
    const viewUrl = `${createModuleUrl('iot-max')}/iot/screen/projectBuilding/${
      this.third.projectId
    }?b=${this.third.buildId}&c=${this.data.pkCameraId}&p=${this.data.id}`;
    const data = {
      buildingId: this.third.buildId,
      buildingName: this.third.buildingName,
      cameraId: this.data.pkCameraId,
      issueType: this.issueType,
      screenshotUrl: this.data.ossUrl ? this.data.ossUrl : this.screenshotUrl,
      warningNoticeType: this.warningNoticeType,
      projectName: this.third.projectName,
      problemDescription: this.problemDescription,
      viewUrl: viewUrl,
      alarmMessageId: Date.now() + this.data.id,
    };
    try {
      await sendWarningMessage(data);
      this.sendLoading = false;
      this.$message.success('发送成功');
      this.close();
    } catch {
      this.sendLoading = false;
    }
  }
  @Emit('close')
  close() {}
}
</script>

<style lang="less" module>
@commonColor: rgba(13, 200, 254, 1);
.warningModal {
  padding: 0 0.2rem;
  color: #fff;
  :global {
    .ant-input-disabled,
    .ant-input[disabled] {
      background-color: transparent;
    }
  }
  :global(.ant-radio) {
    font-size: 0.14rem;
  }
  :global(.ant-radio-inner) {
    width: 0.14rem;
    height: 0.14rem;
    top: -0.01rem;
    border-radius: 1rem;
  }
  :global(.ant-radio-inner::after) {
    background-color: @commonColor;
    top: 0.03rem;
    left: 0.03rem;
    width: 0.06rem;
    height: 0.06rem;
    border-radius: 0.08rem;
  }
  :global(span.ant-radio + *) {
    font-size: 0.14rem;
  }
  :global(label) {
    color: #fff;
  }
  .textarea {
    border-color: @commonColor;
    margin-top: 0.1rem;
    height: 1rem;
    color: #fff;
    height: 1.05rem;
    min-height: 1.05rem;
    font-size: 0.14rem;
    :global(.ant-input) {
      font-size: 0.14rem;
    }
  }
  .point {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    margin-right: 0.1rem;
    line-height: 0.2rem;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    background-color: #05c9ed;
    margin-right: 0.1rem;
    font-size: 0.14rem;
  }
  p {
    margin: 0.2rem 0;
    font-size: 0.14rem;
  }
  img {
    width: 3rem;
    height: 1.2rem;
    border-radius: 0.05rem;
  }
}
</style>
