import { getBackendAuth } from '@/services/oss/oss';

export const formatPictureUrl = async (array = []) => {
  const outs = [];
  array.forEach((item, index) => {
    if (
      item.screenshotUrl.indexOf('http') === -1 &&
      item.alarmMessageType === 'MANUAL'
    ) {
      outs.push({
        ...item,
        index,
      });
    }
  });
  const filters = outs.map(v => v.screenshotUrl);
  if (filters.length > 0) {
    const results = await getBackendAuth([...filters]);
    outs.forEach((item, index) => {
      if (array[item.index].screenshotUrl === item.screenshotUrl) {
        array[item.index].ossUrl = array[item.index].screenshotUrl;
        array[item.index].screenshotUrl = results[index];
      }
    });
  }
  return array;
};
