<template>
  <div
    :class="[$style.monitor_area]"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
  >
    <div v-if="showScreenshot" :class="$style.screenshot_showarea">
      <div :class="$style.screenshot_buttons">
        <div
          :class="$style.button"
          @click="
            openWarningModal(
              currentShot !== -1 ? screenshotList[currentShot] : {},
            )
          "
          v-if="noVisitorMode"
        >
          <i :class="$style.iconfont" class="iconfont iconyujing"></i>预警处理
        </div>
        <div :class="$style.button" @click="hideShot">
          <i :class="$style.iconfont" class="iconfont iconguanbi"></i>关闭
        </div>
      </div>
      <a-carousel
        :class="$style.carousel"
        ref="carousel"
        arrows
        :dots="false"
        :beforeChange="carouselChange"
      >
        <template #prevArrow>
          <a-icon type="left" :class="$style.carousel_arrow_l" />
        </template>
        <template #nextArrow>
          <a-icon type="right" :class="$style.carousel_arrow_r" />
        </template>
        <img
          v-for="item in screenshotList"
          :key="item.id"
          :src="item.screenshotUrl"
        />
      </a-carousel>
    </div>
    <div v-else-if="!videoUrl" :class="$style.outlineBox">
      <div>
        <span
          :class="$style.outlineIcon"
          class="TC tc-icon-shexiangtoujinyong"
        ></span>
        <p>当前摄像头已离线，请稍后再试</p>
      </div>
    </div>
    <video-view
      v-else
      :styleObject="{
        operation: { padding: '0 0.1rem' },
        wrapPlayer: { display: iframeHover ? 'flex' : 'none' },
      }"
      :class="$style.iframe"
      :url="video.url"
      :accessToken="video.token"
      :enableBack="true"
    />
  </div>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import VideoView from '@/components/video-view';
import createModal from '@/views/iot/screen/components/message/modal.js';
import { projectModule, monitorModule } from '@/enum/store.js';
import WarningMesForm from './warning-mes-form.vue';

@Component({
  components: {
    VideoView,
  },
})
export default class MonitorPlay extends Vue {
  @Inject('noVisitorMode') noVisitorMode;

  @monitorModule.State video;
  @monitorModule.State showScreenshot;
  @monitorModule.State screenshotList;

  @monitorModule.Mutation updateShowShot;
  hideShot() {
    this.updateShowShot(false);
  }

  get videoUrl() {
    return this.video.url;
  }

  gotoCarousel(val) {
    this.$refs.carousel
      ? this.$refs.carousel.goTo(val)
      : setTimeout(() => {
          this.$refs.carousel && this.$refs.carousel.goTo(val);
        }, 200);
  }
  @monitorModule.Mutation updateCurrentShot;
  carouselChange(from, val) {
    this.updateCurrentShot(val);
  }

  iframeHover = false;
  handleMouseenter() {
    this.iframeHover = true;
  }
  handleMouseleave() {
    this.iframeHover = false;
  }

  @monitorModule.State currentShot;
  @projectModule.State third;
  /**
   * @name 打开预警发送弹框
   */
  openWarningModal(item) {
    createModal(
      () => (
        <WarningMesForm
          ref={'WarningMesFormRef'}
          data={item}
          third={this.third}
        />
      ),
      {
        title: '预警发送配置',
        closeTxt: '取消',
        okTxt: '发送',
        ok: () => {
          this.$refs.WarningMesFormRef &&
            this.$refs.WarningMesFormRef.sendMes();
        },
        width: `${this.$size(460)}px`,
      },
    );
  }
}
</script>
<style lang="less" module>
@import './monitor.less';
.monitor_area {
  flex: 1;
  margin: 0 0.2rem;
  border: 1px solid @lineColor;
  overflow: hidden;
  box-shadow: @boxshadow;
  .sreenshot();
  .iframe {
    .sreenshot();
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 9999;
  }
  .screenshot_showarea {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .carousel {
    height: 100%;
    :global(.slick-slider) {
      height: 100%;
    }
    :global(.slick-list) {
      height: 100%;
    }
    :global(.slick-track) {
      height: 100%;
      div {
        height: 100%;
      }
      img {
        height: 100%;
      }
    }
    .carousel_arrow_l {
      color: #fff;
      z-index: 101;
      font-size: 0.64rem;
      left: 0.2rem;
      color: @fontColor;
    }
    .carousel_arrow_r {
      color: #fff;
      z-index: 101;
      font-size: 0.64rem;
      right: 0.6rem;
      color: @fontColor;
    }
  }
  .screenshot_buttons {
    position: absolute;
    top: 0.1rem;
    right: 0.5rem;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button {
      cursor: pointer;
      border: 1px solid @lineColor;
      background-color: @bgColor;
      padding: 0.04rem 0.1rem;
      margin-right: 0.1rem;
      font-size: 0.14rem;
      i {
        margin-right: 0.1rem;
      }
    }
    .iconfont {
      font-size: 0.14rem;
    }
  }
  .outlineBox {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    .outlineIcon {
      font-size: 2rem;
      color: #073b6a;
    }
    p {
      margin-top: 0.42rem;
      font-size: 0.14rem;
      color: #096c9a;
    }
  }
}
</style>
